export const projects = [
    {
      id: 1,
      image: "/library.png",
      title: "Krantikari Library",
      description:
        "A library management website for the Akhil Krantikari political party to manage non-curricular books for WRC College students. Built using Next.js, NextAuth, and NextUI for seamless performance, authentication, and responsive UI.",
      liveDemo: "https://krantikarilibrary.com",
    },
    {
      id: 2,
      image: "/moviehub.png",
      title: "MovieHub",
      description:
        "A movie database website showcasing the latest and popular movies with reviews. Built with Next Js and Tailwind CSS, this app features a search bar, a watchlist, and a movie detail page.",
      liveDemo: "https://moviehub-app.vercel.app/",
      github: "https://github.com/shresthashim/moviehub",
    },
    {
      id: 3,
      image: "/estate.png",
      title: "Hamro Estate",
      description:
        "A real estate platform that helps users find and list properties for rent or sale. Built using MERN Stack with a focus on performance and user experience.",
      liveDemo: "https://hamro-estate.onrender.com",
      github: "https://github.com/shresthashim/hamro-estate",
    },
    {
      id: 4,
      image: "/chat.png",
      title: "ChatHub",
      description:
        "A real-time chat application built with Socket.io and Express. Users can chat with signed users in real-time.",
      liveDemo: "https://chat-app-glgz.onrender.com",
      github: "https://github.com/shresthashim/chat-app",
    },
    {
      id: 5,
      image: "/google.png",
      title: "Mini Google Clone",
      description:
        "A clone of Google's search page, built using Next.js and Tailwind CSS. The project showcases API integration of Google.",
      liveDemo: "https://google-clone-nextjs-14.vercel.app",
      github: "https://github.com/shresthashim/Google-Clone-NextJs14",
    },
    {
      id: 6,
      image: "/blindtrust.png",
      title: "BlindTrust",
      description:
        "An anonymous professional networking platform that connects workers of different companies. The platform allows users to share their experiences by posting it anonymously.",
      liveDemo: "https://100x-dev-hackathon.vercel.app",
      github: "https://github.com/SuzalShrestha/100xDev_hackathon",
    },
  ];
  